<template>

<app-content :loading="is.loading">

	<app-content-head title="Announcement" back="Convention.Announcements.Messages" :readonly="$authorised('con/announcements/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box title="Message" icon="announcements" :readonly="!$authorised('con/announcements/access', 'write')">

			<app-input-content v-model="model.text" :validation="$v.model.text" placeholder="Enter message" />

		</app-content-box>

		<app-content-box title="Settings" icon="settings" :readonly="!$authorised('con/announcements/access', 'write')">

			<app-input-text :toggle="model.url.enabled" v-on:toggle="model.url.enabled = !model.url.enabled" v-model="model.url.value" :validation="$v.model.url.value" label="URL" placeholder="Enter URL" />
			<app-input-date :time="true" :toggle="model.scheduled.enabled" v-on:toggle="model.scheduled.enabled = !model.scheduled.enabled" v-model="model.scheduled.value" :validation="$v.model.scheduled.value" label="Scheduled" placeholder="Enter date..." />
			<app-input-toggle v-model="model.email.enabled" :validation="$v.model.email.enabled" label="E-mail" />
			<app-input-toggle v-model="model.modal.enabled" :validation="$v.model.modal.enabled" label="Pop-up" />
			
		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/announcements/access', 'read'),
			model: {
				id: false,
				text: '',
				url: {
					enabled: false,
					value: ''
				},
				scheduled: {
					enabled: false,
					value: 0
				},
				email: {
					enabled: false
				},
				modal: {
					enabled: false
				}
			}
		}

	},

	validations: {
		model: {
			text: {
				required
			},
			url: {},
			email: {},
			modal: {},
			scheduled: {}
		}
	}

}

</script>

<style scoped>

</style>